/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import RevertLevel from "./RevertLevel";
import { useCompanyLevelManualChange } from "./hooks";
import {
    ADDITIONAL_DATA,
    LEVEL_TYPES,
    LOADERS,
    TIME_PERIODS,
    TimePeriodOption
} from "./types";

import DialogActions from "../../components/DialogActions";

import { DialogProps } from "../../../types";

import Dialog from "../../../../DesignComponents/Dialog";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../DesignComponents/InputField";
import Autocomplete from "../../../../DesignComponents/Autocomplete";
import {
    TOOLTIP_PLACEMENT,
    TooltipLarge
} from "../../../../DesignComponents/Tooltips";
import DatePicker from "../../../../DatePicker";

import ThemeContext from "../../../../../context/theme/themeContext";
import {
    DROPDOWN_SIZES,
    disableDates,
    maxDialogWidth,
    useMediaQueries
} from "../../../../../shared";

const CompanyLevelManualChange = ({ isOpen, close }: DialogProps) => {
    const {
        colorsFacelift: { textPlaceholder }
    } = useContext(ThemeContext);

    const { t } = useTranslation();
    const { toMd, fromMd } = useMediaQueries();

    const {
        isLoading,
        level,
        defaultLevel,
        validUntil,
        hasValidUntilError,
        levelType,
        rootCompany,
        rootCompanyLevel,
        timePeriod,
        isCustomTimePeriod,
        clientEmail,
        salesEmail,
        explanation,
        isClientEmailValid,
        isSalesEmailValid,
        clientEmailError,
        salesEmailError,
        MAX_EXPIRATION_DAYS,
        validateClientEmail,
        validateSalesEmail,
        handleCloseDialog,
        handleSubmit,
        resetData,
        getLevelOptions,
        isLevelOptionDisabled,
        setLevel,
        getTimePeriodOptions,
        setTimePeriod,
        setValidUntil,
        handleEndDateChange,
        handleEndDateError,
        handleChangeAdditionalData
    } = useCompanyLevelManualChange(isOpen, close);

    return (
        <Dialog
            title={t("General##company level")}
            description={t("Dialog##company level change description")}
            isTitleSeparator
            isActionsSeparator
            open={isOpen}
            close={handleCloseDialog}
            submit={handleSubmit}
            TransitionProps={{
                onExited: () => resetData(true)
            }}
            actions={
                <DialogActions
                    onClose={handleCloseDialog}
                    isLoading={isLoading.submit}
                    isReadyToConfirm={
                        level &&
                        validUntil &&
                        !hasValidUntilError &&
                        (clientEmail.length ? isClientEmailValid : true) &&
                        (salesEmail.length ? isSalesEmailValid : true)
                    }
                    confirmationButtonText={t("Button##save")}
                />
            }
            extendToMaxWidth={toMd}
            css={css(fromMd && maxDialogWidth)}
        >
            <div
                css={css({
                    margin: "8px 0 16px 0",

                    "& > div + div": {
                        marginTop: "16px"
                    }
                })}
            >
                {levelType === LEVEL_TYPES.Manual && (
                    <RevertLevel
                        date={validUntil as string}
                        defaultLevel={defaultLevel}
                        resetData={resetData}
                    />
                )}

                <InputField
                    fullWidth
                    size={INPUT_FIELD_SIZES.Medium}
                    labelLeft={t("Dialog##company")}
                    value={rootCompany?.name}
                    readOnly
                />

                <InputField
                    fullWidth
                    size={INPUT_FIELD_SIZES.Medium}
                    labelLeft={t("Dialog##company level type")}
                    isLoading={isLoading[LOADERS.LevelType]}
                    value={
                        isLoading[LOADERS.LevelType]
                            ? ""
                            : t(`General##${levelType}`)
                    }
                    readOnly
                />

                <Autocomplete
                    labelLeft={t("General##company level")}
                    fullWidth
                    isRequired
                    value={level}
                    size={DROPDOWN_SIZES.Medium}
                    options={getLevelOptions()}
                    getOptionDisabled={option => isLevelOptionDisabled(option)}
                    onChange={(_, val) => setLevel(val)}
                    renderOption={(props, value) => {
                        const { key, ...restProps } = props;

                        return (
                            <TooltipLarge
                                key={value}
                                title={
                                    isLevelOptionDisabled(value)
                                        ? t(
                                              "Dialog##downgrading company level is not possible"
                                          )
                                        : ""
                                }
                                placement={TOOLTIP_PLACEMENT.Top}
                            >
                                <li>
                                    <span {...restProps}>
                                        {value}

                                        {value === rootCompanyLevel && (
                                            <span
                                                css={css({
                                                    color: textPlaceholder,
                                                    marginLeft: "5px"
                                                })}
                                            >
                                                ({t("Dialog##current level")})
                                            </span>
                                        )}
                                    </span>
                                </li>
                            </TooltipLarge>
                        );
                    }}
                />

                <Autocomplete
                    labelLeft={t("Dialog##time period")}
                    textFieldParams={{
                        placeholder: t("Dialog##select")
                    }}
                    fullWidth
                    isRequired
                    loading={isLoading[LOADERS.LevelType]}
                    value={timePeriod}
                    size={DROPDOWN_SIZES.Medium}
                    options={
                        isLoading[LOADERS.LevelType]
                            ? []
                            : getTimePeriodOptions()
                    }
                    getOptionLabel={option => option.value}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    onChange={(_: any, val: TimePeriodOption | null) => {
                        setTimePeriod(val);

                        const validUntilValue =
                            val?.id === TIME_PERIODS.January31NextYear
                                ? val.value
                                : null;

                        setValidUntil(validUntilValue);
                    }}
                />

                <DatePicker
                    label={t("Dialog##valid until")}
                    disablePast
                    value={validUntil ? moment.utc(validUntil) : null}
                    shouldDisableDate={day =>
                        disableDates(day, MAX_EXPIRATION_DAYS, "days")
                    }
                    readOnly={!isCustomTimePeriod}
                    onChange={handleEndDateChange}
                    getError={handleEndDateError}
                    textFieldParams={{
                        required: true,
                        tooltipProps: {
                            title: !isCustomTimePeriod
                                ? t(
                                      "Dialog##valid until date disable description"
                                  )
                                : "",
                            disableInteractive: true
                        },
                        timeZoneExplanatory: "(UTC+00:00)"
                    }}
                />

                <InputField
                    fullWidth
                    size={INPUT_FIELD_SIZES.Medium}
                    labelLeft={t("Dialog##client email")}
                    placeholder={t("General##enter here")}
                    isLoading={isLoading[LOADERS.LevelType]}
                    value={clientEmail}
                    onBlur={validateClientEmail}
                    errorText={isClientEmailValid ? "" : clientEmailError}
                    name={ADDITIONAL_DATA.ClientEmail}
                    onChange={handleChangeAdditionalData}
                />

                <InputField
                    fullWidth
                    size={INPUT_FIELD_SIZES.Medium}
                    labelLeft={t("Dialog##sales email")}
                    placeholder={t("General##enter here")}
                    isLoading={isLoading[LOADERS.LevelType]}
                    value={salesEmail}
                    onBlur={validateSalesEmail}
                    errorText={isSalesEmailValid ? "" : salesEmailError}
                    name={ADDITIONAL_DATA.SalesEmail}
                    onChange={handleChangeAdditionalData}
                />

                <InputField
                    fullWidth
                    size={INPUT_FIELD_SIZES.Medium}
                    labelLeft={t("Dialog##explanation")}
                    placeholder={t("General##enter here")}
                    isLoading={isLoading[LOADERS.LevelType]}
                    value={explanation}
                    name={ADDITIONAL_DATA.Explanation}
                    onChange={handleChangeAdditionalData}
                />
            </div>
        </Dialog>
    );
};

export default CompanyLevelManualChange;
